var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "right": "",
      "toggle-class": "d-flex align-items-center dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-sm-flex d-none user-nav"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder text-light mb-0"
        }, [_vm._v(" " + _vm._s(_vm.userData.karyawan ? _vm.userData.karyawan.nama_lengkap : _vm.userData.email) + " ")]), _c('span', {
          staticClass: "user-status text-light"
        }, [_vm._v(_vm._s(_vm.userData.karyawan ? _vm.userData.karyawan.jabatan ? _vm.userData.karyawan.jabatan.nama_jabatan.toUpperCase() == "CABANG" ? "TOKO" : _vm.userData.karyawan.jabatan.nama_jabatan.toUpperCase() : "-" : "-") + " ")])]), !_vm.myAvatarProfile ? _c('b-avatar', {
          staticClass: "badge-minimal",
          attrs: {
            "size": "40",
            "src": _vm.userData.avatar,
            "variant": "white",
            "badge": "",
            "badge-variant": "success"
          }
        }, [!_vm.userData.karyawan ? _c('feather-icon', {
          attrs: {
            "icon": "UserIcon",
            "size": "22"
          }
        }) : _vm._e()], 1) : _c('img', {
          staticClass: "rounded",
          staticStyle: {
            "width": "30px"
          },
          attrs: {
            "src": _vm.myAvatarProfile,
            "alt": "profile"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.isCabang ? _c('b-dropdown-item', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "right": "",
      "href": "https://kasir.armezza.com",
      "target": "_blank",
      "rel": "noopener noreferrer",
      "title": "Kasir",
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "MonitorIcon"
    }
  }), _c('span', [_vm._v("Kasir")])], 1) : _vm._e(), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'route-akun-pengguna'
      },
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "UserIcon"
    }
  }), _c('span', [_vm._v("Pengguna")])], 1), _vm.canSetting ? _c('b-dropdown-divider') : _vm._e(), _vm.canSetting ? _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/setting');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "SettingsIcon"
    }
  }), _c('span', [_vm._v("Pengaturan")])], 1) : _vm._e(), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v("Logout")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }